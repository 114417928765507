@import '_styles/variables';
@import '_styles/mixins';

.ArticleTemplate {
  word-break: break-word;

  &__previewBanner {
    background-color: #ec1717;
    padding: 52px var(--fh-gutter) var(--fh-spacerInline);
    text-align: center;

    @include respondAbove(l) {
      padding: var(--fh-spacerInline) var(--fh-gutter);
    }
  }

  &__previewBannerText {
    margin: 0;

    &::before {
      display: inline;
      content: attr(title);
    }
  }

  &__footer {
    padding: 0.1px 0;
    background-color: #121211;
  }
}
