@import '_styles/variables';
@import '_styles/mixins';

.VimeoPlayer {
  position: relative;
  padding-top: 66.67%;
  margin: var(--fh-spacerBlock) 0;
  border: 1px solid var(--fh-colorBorder);

  &__blurhash {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 0.75s var(--fh-easeOut);
    z-index: 1;
    pointer-events: none;
  }

  &__blurhash--hide {
    opacity: 0;
  }

  &__wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
