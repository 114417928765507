@import '_styles/variables';
@import '_styles/mixins';

.SiteLogo {
  position: fixed;
  left: var(--fh-gutter);
  top: 20px;
  width: 133px;
  height: 22px;
  z-index: 100;

  @include respondAbove(s) {
    width: 140px;
    height: 23px;
    left: 16px;
  }

  &__logo {
    width: 100%;
    height: 100%;
    transition: all 0.2s var(--fh-easeInOut);

    g {
      // transition: all 0.2s var(--fh-easeInOut);
      fill: #fff;
    }
  }
}
