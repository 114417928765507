@import '_styles/variables';
@import '_styles/mixins';

.SiteMenu {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  background: rgba(19,18,17,0.5);
  display: none;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(30px);

  &__nav {
    font-size: clamp(1.5rem, 3vw, 2.25rem);
    font-weight: 500;
    text-align: center;
  }

  &__navItems {
    margin-top: -4em;

    @include respondAbove(s) {
      margin-top: -2em;
    }
  }

  &__link {
    display: block;
    margin: 0.75em 0;

    @include respondAbove(s) {
      display: inline-block;
      margin: 0 1em;
    }
  }

  &__media {
    display: block;

    @include respondAbove(s) {
      display: none;
    }
  }

  &__mediaLink {
    display: inline-block;
    width: clamp(1.5rem, 3vw, 2.25rem);
    height: clamp(1.5rem, 3vw, 2.25rem);
    margin: 20px;
  }

  &__mediaIcon {
    width: 1.75rem;
    height: 1.75rem;
  }

  &.fade-enter {
    display: grid;
    opacity: 0;
    transition: all 0.5s var(--fh-easeInOut);
    transform: scale(1.1);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: scale(1);
  }

  &.fade-enter-done {
    display: grid;
  }

  &.fade-exit {
    display: grid;
    opacity: 1;
    transition: all 0.3s var(--fh-easeInOut);
    transform: scale(1);
  }

  &.fade-exit-active {
    opacity: 0;
    transform: scale(1.2);
  }
}
