@import '_styles/variables';
@import '_styles/mixins';

.TimeAgo {
  @include fontCaps;
  font-size: 0.625em;
  color: var(--fh-colorFadedText);
  font-weight: 600;
  letter-spacing: 0.15em;
}
