@font-face {
  font-family: 'Silka';
  font-weight: 400;
  font-style: normal;
  src: url('../_fonts/Silka-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Silka';
  font-weight: 500;
  font-style: normal;
  src: url('../_fonts/Silka-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Silka';
  font-weight: 600;
  font-style: normal;
  src: url('../_fonts/Silka-SemiBold.woff2') format('woff2');
}
