@import '_styles/variables';
@import '_styles/mixins';

.ArtistLinks {
  margin: var(--fh-spacerBlock) 0;

  &__grid {
    display: grid;
    grid-template-columns: 1fr;

    @include respondAbove(s) {
      grid-template-columns: 160px 1fr;
      column-gap: var(--fh-spacerBlock);
    }
  }

  &__title {
    margin-top: 0;
  }
}
