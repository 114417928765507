@import '_styles/variables';
@import '_styles/mixins';

.BuyMeACoffee {
  padding: calc(var(--fh-spacerBlock) * 0.75);
  background-color: rgba(255,255,255, 0.04);
  border: 1px solid rgba(var(--fh-colorAccentRGB), 0.5);
  border: 1px solid var(--fh-colorBorder);
  text-align: center;

  &__title {
    margin-top: 0;
    color: #ffdd00;
  }

  &__description {
    margin-bottom: 2em;
  }

  &__badge {
    width: 150px;
    height: 42px;
  }

}
