@import '_styles/variables';
@import '_styles/mixins';

.CardListTiles {

  .CardList {

    &__grid {

      @include respondAbove(s) {
        gap: calc(var(--fh-spacerBlock) * 0.75);
        grid-template-columns: 1fr 1fr;
      }

      @include respondAbove(m) {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    &__gridItem {

      @include respondAbove(s) {
        padding: calc(var(--fh-spacerBlock) * 0.75) 0 0;

        &:nth-child(-n+2) {
          border-top: 0;
          padding-top: 0;
        }

        &:last-child {
          border-bottom: 0;
        }
      }

      @include respondAbove(m) {

        &:nth-child(3) {
          border-top: 0;
          padding-top: 0;
        }
      }
    }
  }

  .Card {

    &__grid {

      @include respondAbove(s) {
        display: block;
        grid-template-columns: unset;
        grid-template-rows: 1fr auto;
      }
    }

    &__gridImage {
      box-shadow: 0px 0px 12px rgba(0,0,0,0.2);
    }

    &__image {

      @include respondAbove(s) {
        padding-top: 66.67%;
      }
    }

    &__category {

      @include respondAbove(s) {
        margin-top: 1.5em;
      }
    }


    &__title {

      @include respondAbove(s) {
        margin: 0.25em 0;
      }
    }

    &__timeAgo {

      @include respondAbove(s) {
        margin-bottom: -0.625em;
      }
    }
  }
}
