@import '_styles/variables';
@import '_styles/mixins';

.ScrollDownNotice {
  width: 160px;
  pointer-events: none;
  text-align: center;
  color: rgba(#fff, 0.8);
  transition: opacity 0.25s var(--fh-easeInOut);
  opacity: 0;

  &--visible {
    opacity: 1;
  }

  &__arrow {
    display: inline-block;
    overflow: hidden;
    width: 32px;
    height: 32px;
  }

  &__arrowIcon {
    height: 100%;
    animation: ScrollDownNotice__arrowAnimation 1.2s infinite;
    animation-timing-function: var(--fh-easeInOut);

    g {
      fill: currentColor;
    }

    @keyframes ScrollDownNotice__arrowAnimation {
      0%   { transform: translateY(-100%); }
      3%   { transform: translateY(-100%); }
      30% { transform: translateY(0); }
      70% { transform: translateY(0);}
      97% { transform: translateY(100%); }
      100% { transform: translateY(100%); }
    }
  }

  &__text {
    @include fontCaps;
    font-size: 0.75em;
    color: currentColor;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.25);
  }
}
