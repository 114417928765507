@import '_styles/variables';
@import '_styles/mixins';

.ImageCompare {
  margin: var(--fh-spacerBlock) 0;

  &__image {
    border: 1px solid var(--fh-colorBorder);
  }

  &__handle {
    display: grid;
    width: 48px;
    height: 100%;
    place-content: center;
    pointer-events: none;
    cursor: ew-resize;
    grid-template-rows: 1fr 48px 1fr;
  }

  &__handleLine {
    text-align: center;
  }

  &__handleLineStroke {
    display: inline-block;
    width: 2px;
    height: 100%;
    background: rgba(255,255,255,0.5);
    box-shadow: 0px 0px 10px rgba(0,0,0,0.25);
    pointer-events: auto;
    overflow: hidden;
  }

  &__handleButton {
    all: unset;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid rgba(255,255,255,0.75);
    pointer-events: auto;
    backdrop-filter: blur(7px);
    box-shadow: 0px 0px 10px rgba(0,0,0,0.25);
    text-align: center;
    color: #fff;
    vertical-align: middle;
    line-height: 0;
  }

  &__handleButtonIcon {
    fill: #fff;
    height: 24px;
  }

  &__caption {
    display: block;
    text-align: center;;
    margin-top: 1em;
  }
}
