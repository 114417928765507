@import '_styles/variables';
@import '_styles/mixins';

.Error404Route {

  &__title {
    text-align: center;
    margin-bottom: 0.5em;
  }

  &__subtitle {
    margin: 0;
    color: var(--fh-colorText);
    font-weight: 500;
    text-align: center;
  }
}
