@import '_styles/variables';
@import '_styles/mixins';

.SiteMenuHamburger {
  position: fixed;
  right: 8px;
  top: 8px;
  z-index: 100;
	width: 48px;
	height: 48px;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;

  &__icon {
    width: 22px;
    height: 2px;
    position: absolute;
    left: 3px;
    top: 22px;
    transition: all 0.25s var(--fh-easeInOut);

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 22px;
      height: 2px;
      background: #fff;
      transition: all 0.25s var(--fh-easeInOut);
    }

    &:before {
      transform: rotate(0deg) translate(0px,-4px);
    }

    &:after {
      transform: rotate(0deg) translate(0px,4px);
    }
  }

  &:hover &__icon {

    &:before {
      transform: rotate(0deg) translate(-4px, -4px);
    }

    &:after {
      transform: rotate(0deg) translate(4px, 4px);
    }
  }

  &--active &__icon {

    &:before {
      transform: rotate(-45deg) translate(0px,0px);
    }

    &:after {
      transform: rotate(45deg) translate(0px,0px);
    }
  }

  &--active:hover &__icon {

    &:before {
      transform: rotate(0deg) translate(0px,0px);
    }

    &:after {
      transform: rotate(0deg) translate(0px,0px);
    }
  }
}
