@import '_styles/variables';
@import '_styles/mixins';

.ProductLinks {
  margin: var(--fh-spacerBlock) 0;
  padding: calc(var(--fh-spacerBlock) * 0.75);
  background-color: rgba(255,255,255, 0.04);
  border: 1px solid var(--fh-colorBorder);
  box-shadow: 0px 10px 20px rgba(0,0,0,0.2);

  &__grid {
    display: grid;
  }

  &__title {
    margin-top: 0;
  }

  &__caption {
    display: block;
    margin-top: 2em;
  }
}
