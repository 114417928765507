@import '_styles/variables';
@import '_styles/mixins';

.MediaIcons {
  position: fixed;
  right: 54px;
  top: 7px;
  z-index: 100;

  &__grid {
    display: grid;
    grid-auto-flow: column;
    // gap: 20px;
    justify-content: center;
    align-items: center;
  }

  &__link {
    display: none;
    padding: 12px 15px;
    line-height: 1;
    color: #fff;

    @include respondAbove(s) {
      display: inline-block;
    }
  }

  &__icon {
    width: 21px;
    height: 20px;
  }

  &__link3 {
    transform: translateY(1px);
  }
}
