@import '_styles/variables';
@import '_styles/mixins';

.ArticleHeader {

  &__imageWrap {
    position: relative;
    margin: 0 auto var(--fh-spacerBlock);
  }

  &__image {
    border-bottom: 3px solid var(--fh-colorBorder);
  }

  &__caption {
    display: block;
    text-align: center;;
    margin-top: 1em;
  }

  &__imageScrollDownNotice {
    position: fixed;
    left: calc(50% - 80px);
    bottom: 32px;
  }

  &__title {
    margin: 0;
    text-align: center;;
  }

  &__date {
    @include fontCaps;
    margin-bottom: 0;
    font-size: 0.75em;
    opacity: 0.5;
    text-align: center;
  }
}
