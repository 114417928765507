@import '_styles/variables';
@import '_styles/mixins';

.LinksList {

  &__group {
    border-top: 1px solid var(--fh-colorBorder);
    padding: 1em 0;

    &:last-child {
      border-bottom: 1px solid var(--fh-colorBorder);
    }
  }

  &__groupTitle {
    @include fontCaps;
    font-size: 0.625em;
    margin: 0.25em 0 0.5em;
    color: var(--fh-colorAccent);

    @include respondAbove(s) {
      font-size: 0.75em;
    }
  }

  &__item {
    margin: 0.5em 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__itemName {
    color:rgba(255,255,255, 0.4);
  }
}
