@import '_styles/variables';
@import '_styles/mixins';

.CardList {

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
  }

  &__gridItem {
    border-top: 1px solid var(--fh-colorBorder);
    padding: var(--fh-spacerInline) 0;

    &:last-child {
      border-bottom: 1px solid var(--fh-colorBorder);
    }
  }
}
