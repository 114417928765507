@import '_styles/variables';
@import '_styles/mixins';

.PageSection {
  margin: var(--fh-spacerBlock) auto;
  padding: 0;

  &--gutter {
    padding-left: var(--fh-gutter);
    padding-right: var(--fh-gutter);
  }

  &--tintWhite {
    margin: 0 auto;
    padding-top: var(--fh-spacerBlock);
    padding-bottom: var(--fh-spacerBlock);
    background: rgba(255,255,255,0.05);
  }

  &__inner {
    margin: 0 auto;
  }
}
