@import '_styles/variables';
@import '_styles/mixins';

.ImageCarousel {
  margin: var(--fh-spacerBlock) 0;

  &__carousel {
    border: 1px solid var(--fh-colorBorder);
  }

  &__arrow {
    position: absolute;
    top: calc(50% - 26px);
    z-index: 1;
    width: 36px;
    height: 52px;
    background: transparent;
    border: 0;
    padding: 10px;
    margin: 0;
    cursor: pointer;
    fill: white;
    filter: drop-shadow(0 0 10px rgba(0,0,0,0.25));

    @include respondAbove(s) {
      top: calc(50% - 30px);
      width: 40px;
      height: 60px;
    }
  }

  &__arrowPrev {
    left: 5px;

    @include respondAbove(s) {
      left: 10px;
    }
  }

  &__arrowNext {
    right: 5px;

    @include respondAbove(s) {
      right: 10px;
    }
  }

  &__caption {
    display: block;
    text-align: center;;
    margin-top: 1em;
  }
}
