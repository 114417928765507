@import '_styles/variables';
@import '_styles/mixins';

.Card {
  display: block;

  &__grid {
    display: grid;
    grid-template-columns: 20% 1fr;
    gap: var(--fh-spacerBlock);
    align-items: center;
  }

  &__gridImage {
    overflow: hidden;
    border: 1px solid var(--fh-colorBorder);
    position: relative;
  }

  &__image {
    transition: transform 0.5s var(--fh-easeInOut);
  }

  &__imageProtection {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    box-shadow: inset 0 0 50px rgba(0,0,0,0.3);
    background-color: rgba(0,0,0,0.3);
    transition: background-color 0.5s var(--fh-easeInOut);

    @include respondAbove(s) {
      display: block;
    }
  }

  &:hover &__image {
    transform: scale(1.15);
  }

  &:hover &__imageProtection {
    background-color: rgba(0,0,0,0);
  }


  &__gridText {
    line-height: 1.625;
  }

  &__category {
    color: var(--fh-colorAccent);
    @include fontCaps;
    font-size: 0.625em;

    @include respondAbove(s) {
      font-size: 0.75em;
    }
  }

  &__title {
    margin: 0.125em 0;
    transition: color 0.5s var(--fh-easeInOut);
  }

  &__description {
    margin: 0.25em 0 0;
    font-size: 0.875rem;
    font-weight: 500;
    transition: color 0.5s var(--fh-easeInOut);

    @include respondAbove(s) {
      font-size: 1rem;
      margin: 0.375em 0 0.625em;
    }
  }

  &:hover &__description {
    color: #fff;
  }

  &__timeAgo {
    display: none;
    margin: 0;

    @include respondAbove(s) {
      display: block;
    }
  }
}
