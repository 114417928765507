@import '_styles/variables';
@import '_styles/mixins';

.HeroTile {
  display: block;
  position: relative;
  overflow: hidden;

  @include respondAbove(m) {
    padding: 4vw 0;
  }

  &__back {
    display: none;
    grid-template-columns: 1fr 1fr;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;

    @include respondAbove(m) {
      display: grid;
    }
  }

  &__backOverlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // background-color: rgba(#191817, 0.5);
    background: linear-gradient(180deg, rgba(#191817, 0.5) 70%, rgba(#191817, 1));
    // opacity: 0.7;
    backdrop-filter: blur(40px);
  }

  &__tilesWrapper {
    margin-top: 0;

    @include respondAbove(s) {
      margin-top: var(--fh-spacerBlock);
      margin-left: var(--fh-gutter);
      margin-right: var(--fh-gutter);
    }
  }

  &__tiles {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--fh-spacerBlock);

    @include respondAbove(m) {
      grid-template-columns: 1fr 1fr;
      gap: 0;
      border: 1px solid var(--fh-colorBorder);
      box-shadow: 0px 10px 20px rgba(0,0,0,0.2);
    }
  }

  &__tile {
    position: relative;
    overflow: hidden;

    @include respondAbove(s) {
      border: 1px solid var(--fh-colorBorder);
      box-shadow: 0px 10px 20px rgba(0,0,0,0.2);
    }

    @include respondAbove(m) {
      border: 0;
      box-shadow: none;
    }

    &:first-child {

      @include respondAbove(m) {
        border-right: 1px solid var(--fh-colorBorder);
      }
    }

  }

  &__image {
    transition: transform 0.5s var(--fh-easeInOut);
    padding-top: 80%;

    @include respondAbove(s) {
      padding-top: 66.67%;
    }

    @include respondAbove(m) {
      padding-top: 75%;
    }

    @include respondAbove(l) {
      padding-top: 66.67%;
    }
  }

  &__tile:hover &__image {
    transform: scale(1.1);
  }

  &__imageProtection {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    box-shadow: inset 0 0 50px rgba(0,0,0,0.3);
    background: linear-gradient(0, rgba(#191817, 0.6), rgba(#191817, 0.3) 50%);
    transition: opacity 0.5s var(--fh-easeInOut);
  }

  &__tile:hover &__imageProtection {
    opacity: 0;
  }

  &__text {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    padding: calc(var(--fh-spacerBlock) * 0.75);
  }

  &__category {
    color: var(--fh-colorAccent);
    @include fontCaps;
    font-size: 0.625em;

    @include respondAbove(s) {
      font-size: 0.75em;
    }
  }

  &__title {
    margin: 0.125em 0;
    line-height: 1.5;
    transition: color 0.5s var(--fh-easeInOut);

    @include respondAbove(s) {
      margin: 0.25em 0;
    }
  }

  &__description {
    margin: 0 0 0.25em;
    font-weight: 500;
    color: var(--fh-colorText);
    transition: color 0.5s var(--fh-easeInOut);

    @include respondAbove(s) {
      margin: 0 0 0.5em;
    }
  }

  &__tile:hover &__description {
    color: #fff;
  }

  &__read {
    display: block;
    margin-top: 0.25em;

    @include respondAbove(s) {
      margin-top: 0.5em;
    }
  }

  &__tile:hover &__read {

    .ArrowAction {

      &__label {
        color: #fff;
      }

      &__arrow {
        color: #fff;
        transform: translateX(0.25em) scale(1.1);
      }
    }
  }

  &__media {
    text-align: center;
  }
}
