//
//
//  respondAbove
//
//

@mixin respondAbove($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//
//  fontCaps
//
//

@mixin fontCaps {
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}
