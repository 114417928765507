@import '_styles/variables';
@import '_styles/mixins';

.SiteFooter {
  text-align: center;

  &--margin {
    margin: var(--fh-spacerInline);
  }
}
