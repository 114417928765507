
@import '_styles/variables';
@import '_styles/mixins';

.ArticleImage {
  margin: var(--fh-spacerBlock) 0;

  &__image {
    border: 1px solid var(--fh-colorBorder);
  }

  &__caption {
    display: block;
    text-align: center;
    margin-top: 1em;
  }
}
