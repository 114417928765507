@import '_styles/variables';
@import '_styles/mixins';

.MoreReading {

  &__title {
    margin-top: 0;
    text-align: center;;
  }
}
