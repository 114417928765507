@import '_styles/variables';
@import '_styles/mixins';

.LazyImage {
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  background-color: #181716;

   &__blurhash {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.75s var(--fh-easeOut);
    pointer-events: none;
  }

  &__image--loaded {
    opacity: 1;
  }
}
