//
//
// Anchors - there's a ton of extra styles in this in the event a button needs to be styled like an anchor
//
//

.u-anchor {
  color: currentColor;
  font: inherit;
  text-decoration: none;
  padding: 0;
  border: none;
  color: inherit;
  background: none;
  cursor: pointer;
  transition: all 0.3s var(--fh-easeInOut);

  &--fadeHover {

    &:hover {
      opacity: 0.5;
    }
  }

  &--accent {
    color: var(--fh-colorAccent);

    &:hover {
      color: #fff;
    }
  }

  &--accentHover {

    &:hover {
      color: var(--fh-colorAccent);
    }
  }

  &--underline {
    padding-bottom: 2px;
    border-bottom: 1px solid currentColor;
  }
}



//
//
// Buttons
//
//

.u-button {
  display: inline-block;
  padding: 1.25em 1.5em;
  @include fontCaps;
  font-size: 0.75em;
  line-height: 1;
  text-decoration: none;
  border: 1px solid rgba(var(--fh-colorAccentRGB), 0.5);
  border-radius: 2em;
  background-color: rgba(var(--fh-colorAccentRGB), 0.1);
  color: var(--fh-colorAccent);
  cursor: pointer;
  transition: all 0.3s var(--fh-easeInOut);

  // &:hover {
  //   color: #fff;
  //   background-color: rgba(#fff, 0.1);
  //   border-color: #fff;
  // }

  // &--accent {
  //   background-color: var(--fh-colorAccent);

  //   &:hover {
  //     background-color: #fff;
  //     color: var(--fh-colorAccent);
  //   }
  // }
}



//
//
// Lists
//
//

.u-bulletList {

  > li {
    margin-bottom: 0.25em;
    padding-left: 0.5em;
  }
}




//
//
// Text Align
//
//

.u-textAlign--center {
  text-align: center;
}




//
//
// Animations
//
//

.a-routeFadeIn {
  animation: a-routeFadeIn 0.75s var(--fh-easeOut) 0.25s;
  animation-fill-mode: both;
}

@keyframes a-routeFadeIn {
  0%    { opacity: 0; height: 0; overflow: hidden; }
  0.01% { height: auto; overflow: visible; }
  100%  { opacity: 1; }
}

.a-routeFadeIn.fade-exit {
  animation: none;
  animation-fill-mode: none;
  opacity: 1;
  transition: opacity 0.2s var(--fh-easeOut);
  transition-delay: 0;
}

.a-routeFadeIn.fade-exit-active {
  opacity: 0;
}

