:root {
  --fh-gutter:            calc(8px + 1.25vw);
  --fh-spacerBlock:       calc(8px + 2vw);
  --fh-spacerInline:      min(calc(1em + 1vw), 2em);

  --fh-colorAccent:       #fc735d;
  --fh-colorAccentRGB:    252, 115, 93;
  --fh-colorBackground:   #191817;
  --fh-colorBorder:       rgba(255, 255, 255, 0.1);
  --fh-colorText:         rgba(255,255,255, 0.6);
  --fh-colorFadedText:    rgba(255,255,255, 0.35);

  --fh-fontFamily:        'Silka', 'serif';
  --fh-lineHeight:        1.875;

  --fh-easeInOut:         cubic-bezier(.37,0,0,1);
  --fh-easeOut:           cubic-bezier(.05,.4,0,1);
  --fh-easeIn:            cubic-bezier(1,0,.95,.6);

  @include respondAbove(s) {
    --fh-gutter:          calc(24px + 1.25vw);
    --fh-spacerBlock:       calc(24px + 2vw);
  }
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--fh-colorBackground);
}

body {
  font-family: var(--fh-fontFamily);
  font-size: 16px;
  font-weight: 400;
  line-height: var(--fh-lineHeight);
  color: var(--fh-colorText);
  background-color: var(--fh-colorBackground);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4 {
  font-family: var(--fh-fontFamily);
  font-weight: 600;
  margin: 2em 0 1em;
  color: #fff;
}

h1 {
  font-size: clamp(1.5rem, 3vw, 2.25rem);
}

h2 {
  font-size: clamp(1.25rem, 2.5vw, 1.5rem);
}

h3 {
  font-size: clamp(1rem, 2vw, 1.25rem);
}

h4 {
  font-size: 1rem;
}

p {
  margin: 1em 0;
}

small {
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--fh-colorFadedText);
  line-height: 1.5em;
}

hr {
  border: 0;
  border-top: 1px solid rgba(255,255,255,0.1);
  margin: var(--fh-spacerBlock) auto;
}
