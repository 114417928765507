@import '_styles/variables';
@import '_styles/mixins';

.ArrowAction {
  font-weight: 600;

  &__label {
    margin-right: 0.5em;
    color: currentColor;
    transition: color 0.4s var(--fh-easeInOut);
  }

  &__arrow {
    transition: all 0.4s var(--fh-easeInOut);
    width: 0.9em;
    height: 0.9em;
    color: rgba(255,255,255,0.6);
    margin-bottom: -0.05em;

    g {
      fill: currentColor;
    }
  }

  &:hover &__label {
    color: #fff;
  }

  &:hover &__arrow {
    color: #fff;
    transform: translateX(0.25em) scale(1.1);
  }
}
