
@import '_styles/variables';
@import '_styles/mixins';

.ArticleImages {
  margin: var(--fh-spacerBlock) 0;

  &__grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 8px;
  }

  &__image {
    grid-column: span 3;
    border: 1px solid var(--fh-colorBorder);
  }

  &__caption {
    display: block;
    text-align: center;
    margin-top: 1em;
  }
}
